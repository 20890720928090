<template>
  <div>
    <v-btn
      color="success"
      @click="generatePdf"
    >
      generate PDF
    </v-btn>
    <v-card
      class="mx-auto"
      max-width="400"
      tile
    >
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    student: [],
    emails: [],
    listemails: '',
  }),
  mounted() {
    this.fetchStudent()
  },
  methods: {
    fetchStudent() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/students/1225`)
        .then(res => {
          this.student = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },

    generatePdf() {
      const data = {
        student: this.student,
        dateDebut: this.$date(new Date(this.student.debutFormation), 'dd/MM/yyyy'),
        dateFin: this.$date(new Date(this.student.finFormation), 'dd/MM/yyyy'),
        review: this.student.review,
        tuteur: this.student.tuteurs[0].nom,
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/reviews/generate-pdf`, data)
        .then(res => {
          console.log(res)
        })
        .catch(err => console.log(err))
        .finally()
    },

  },
}
</script>

<style>

</style>
